import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConstantsService } from 'src/app/core/services/constants.service';
import { RcService } from 'src/app/core/services/rc.service';
import { CopyDetailsComponent } from 'src/app/pages/copy-details/copy-details.component';
import { HomeService } from 'src/app/pages/home/home.service';
import { SettingsComponent } from 'src/app/pages/training/settings/settings.component';
import { TrainingService } from 'src/app/pages/training/training.service';
import { ChangePasswordComponent } from 'src/app/shared/change-password/change-password.component';
import { ItPasswordResetComponent } from 'src/app/shared/it-password-reset/it-password-reset.component';
import { ProfileComponent } from 'src/app/shared/profile/profile.component';
import { SettingComponent } from 'src/app/shared/setting/setting.component';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  tenant: any = {};
  userData: any;
  employeeNumber: number = 0;
  
  isInventoryAccess: boolean = false;

  hasEvents: boolean = false;
  private eventsSubscription!: Subscription;

  private _sidebarOpen = false;
  private _submenuOpen = false;

  isSupervisor: boolean = false;
  isReviewer: boolean = false;
  IsTraining: boolean = false;
  IsTrainingAdmin: boolean = false;
  MailSettings: boolean = false;
  TrainingCourse: boolean = false;
  CourseNotifications: boolean = false;
  TrainingGroup: boolean = false;
  EmployeeTraining: boolean = false;
  EmployeeNotifications: boolean = false;
  isUpdatePasswordAvailable: boolean = false;
  forcePasswordChange: boolean = false;
  isIT: boolean = false;
  forcePasswordReset: boolean = false;

  notifications: any[] = [];
  NotificationPagingInfo: any = {
    Search: '',
    Searchcolumn: '',
    Page: 1,
    ItemsPerPage: 10,
    SortColumn: 'ValidityExpired',
    SortBy: 'ASC',
    reverse: false,
    EmployeeNumber: '',
  };
  trainingNotificationList: any[] = [];

  constructor(
    private el: ElementRef,
    private router: Router,
    public rcService: RcService,
    private authService: AuthService,
    private dialogRef: MatDialog,
    private constantService: ConstantsService,
    private sharedService: SharedService,
    private homeService: HomeService,
    private trainingService: TrainingService,
  ) {
  }

  ngOnInit(): void {
    this.authService.getCurrentUser().pipe(take(3)).subscribe((user) => {
      this.userData = user;
      this.employeeNumber = user?.credentials?.EmployeeNumber;
      this.isSupervisor = user?.isSupervisor;
      // this.isReviewer = user?.canReviewFirstLevel && user?.tailoring?.SecondApproval;
      this.isReviewer = user?.canReviewFirstLevel;
      this.IsTraining = user?.IsTraining;
      this.IsTrainingAdmin = user?.IsTrainingAdmin;
      this.MailSettings = user?.MailSettings;
      this.TrainingCourse = user?.TrainingCourse;
      this.CourseNotifications = user?.CourseNotifications;
      this.TrainingGroup = user?.TrainingGroup;
      this.EmployeeTraining = user?.EmployeeTraining;
      this.EmployeeNotifications = user?.EmployeeNotifications;
      this.isUpdatePasswordAvailable = user?.tailoring?.AllowChangePassword;
      this.forcePasswordReset = user?.forcePasswordReset;
      let profileData = this.userData?.profile?.length > 0 && !Array.isArray(this.userData?.profile) ? JSON.parse(this.userData?.profile) : this.userData?.profile;
      if (profileData?.some((itm: any) => itm.ProfileTypeID == 5 && itm.SettingValue === 'true')) {
        this.isIT = true;
      }
      this.NotificationPagingInfo = {
        ...this.NotificationPagingInfo,
        EmployeeNumber: this.employeeNumber
      }
      this.checkInventoryAccess();
      this.getNotifications();
    });

    let currentTenant = localStorage.getItem('tenant');
    if (currentTenant !== null) {
      this.tenant = JSON.parse(currentTenant);
    }

    this.eventsSubscription = this.sharedService.events$.subscribe(events => {
      this.hasEvents = events && events.length > 0;
    });

    if (this.forcePasswordReset) {
      let dialogRef = this.dialogRef.open(ChangePasswordComponent, {
        disableClose: true,
        backdropClass: 'custom-dialog-overlay',
        data: {
          forcePassword: true,
        }
      });

      // Subscribe to the afterClosed event to handle actions after the dialog is closed
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'logout') {
          this.onLogout();
        }
      });

    }

  }

  checkInventoryAccess(): void {
    this.authService.checkInventoryAccess(this.employeeNumber).subscribe(
      (response: any) => {
        if (response.IsInventoryAccess) {
          this.authService.setCurrentUser({ ...this.userData, ApplicationUserName: response.LienceUserName });
          this.isInventoryAccess = true;
          // this.router.navigate(['/inventory']);
        } else {
          // Handle other access or redirection logic
          this.isInventoryAccess = false;
          // this.router.navigate(['/no-access']);
        }
      },
      (error) => {
        console.error('Failed to load access rights', error);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  getImageUrl(image: any): string {
    // return image.Image === this.constantService.DEFAULT_LOGO ? this.constantService.DEFAULT_LOGO : `${image.Root}/${image.Image}`;
    return image.Image === this.constantService.DEFAULT_LOGO ? this.constantService.DEFAULT_LOGO : `${this.constantService.IMAGE_URL}/${image.Image}`;
  }

  // Method to handle image loading errors
  handleImageError(image: any) {
    // Update the image URL to the default image
    this.tenant.Image = this.constantService.DEFAULT_LOGO;
  }

  get sidebarOpen(): boolean {
    return this._sidebarOpen;
  }

  set sidebarOpen(value: boolean) {
    this._sidebarOpen = value;
  }

  get submenuOpen(): boolean {
    return this._submenuOpen;
  }

  set submenuOpen(value: boolean) {
    this._submenuOpen = value;
  }


  togglesidebar() {
    // console.log('called')
    this.sidebarOpen = !this.sidebarOpen
  }

  togglemenu() {
    this.submenuOpen = !this.submenuOpen
  }

  settingOpen: boolean = false;
  toggleSetting() {
    this.settingOpen = !this.settingOpen
  }

  submenuStates: { [key: string]: boolean } = {
    'training': false, // Add more submenu keys as needed
    'item-master': false,
    'inventory-master': false
    // Add more submenu keys as needed
  };

  toggleSubmenu(submenuKey: string) {
    Object.keys(this.submenuStates).forEach(key => {
      if (key !== submenuKey) {
        this.submenuStates[key] = false;
      }
    });
    this.submenuStates[submenuKey] = !this.submenuStates[submenuKey];
  }

  goToItemMaster(event: Event): void {
    event.preventDefault();
    this.router.navigate([`/${this.rcService.getTenant()}/inventory/maintenance`]);
  }
  
  goToInventoryMaster(event: Event): void {
    event.preventDefault();
    this.router.navigate([`/${this.rcService.getTenant()}/inventory/master`]);
  }

  // isActive(route: string): boolean {
  //   return this.router.isActive(route, true);
  // }
  isActive(): boolean {
    const url = this.router.url;
    const tenant = this.rcService.getTenant(); // Assuming getTenant is defined in the component
    return url === `/${tenant}` || 
           url === `/${tenant}/home` || 
           url === `/${tenant}/details/categories` || 
           url === '/';
  }

  openCopyDetailDailog() {
    this.dialogRef.open(CopyDetailsComponent);
  }

  openSettingDailog() {
    this.dialogRef.open(SettingComponent);
  }

  onLogout() {
    this.authService.clearUserData();
    this.router.navigate([`/${this.rcService.getTenant()}/auth/login`]);
  }

  //Open training settings dialog
  openTrainingSettingDailog() {
    this.dialogRef.open(SettingsComponent);
  }

  openChangePasswordDailog() {
    this.dialogRef.open(ChangePasswordComponent, {
      data: {
        forcePassword: false,
      },
    });
  }

  openProfileDailog() {
    this.dialogRef.open(ProfileComponent, {
      width: '650px', // Set the desired width
      panelClass: 'custom-profile-setting',
      data: {
        // showEvents: true
      }
    });
  }

  openITPasswordTool() {
    this.dialogRef.open(ItPasswordResetComponent);
  }

  // Handle click event for the entire sidebar
  /* handleSidebarClick(event: Event): void {
    const clickedElement = event.target as HTMLElement;
    const isAnchorOrChildClicked = clickedElement.closest('a') !== null;
    console.log('isAnchorOrChildClicked', isAnchorOrChildClicked);
    if (
      this.sidebarOpen &&
      clickedElement.textContent !== 'Training' &&
      clickedElement.textContent !== 'Item Master' &&
      clickedElement.textContent !== 'Inventory' &&
      !isAnchorOrChildClicked
    ) {
      this.sidebarOpen = false;
    }
  } */
  handleSidebarClick(event: Event): void {
    const clickedElement = event.target as HTMLElement;
    const isSubmenuToggleClicked = clickedElement.closest('.submenu-toggle') !== null;
    const isSubmenuLinkClicked = clickedElement.closest('.submenu-container') !== null;
    const isAnchorOrChildClicked = clickedElement.closest('a') !== null;
    // console.log('isSubmenuToggleClicked', isSubmenuToggleClicked);
    // console.log('isSubmenuLinkClicked', isSubmenuLinkClicked);
    // Check if the click occurred within the sub-menu container
    if (isSubmenuLinkClicked) {
      // Close the sidebar if clicking on any links within the sub-menu
      this.sidebarOpen = false;
    } else if (isSubmenuToggleClicked === false && isSubmenuLinkClicked === false) {
      Object.keys(this.submenuStates).forEach(key => {
        this.submenuStates[key] = false;
      });
      this.sidebarOpen = false;
    }
    else {
      // Close the sidebar only if clicking outside the sub-menu container or on the sub-menu toggle
      if (!isSubmenuToggleClicked) {
        this.sidebarOpen = false;
      }
    }
  }


  /* toggleSubmenu(submenu: string, event: MouseEvent) {
    // Check if the click event originates from the anchor tag or its children
    const targetElement = event.target as HTMLElement;
    const isAnchorOrChildClicked = targetElement.closest('a') !== null;

    if (isAnchorOrChildClicked) {
      // Toggle the submenu only if the click event originates from the anchor tag or its children
      this.submenuStates[submenu] = !this.submenuStates[submenu];
    }
  } */


  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    const clickedInside = this.el.nativeElement.contains(event.target);
    if (!clickedInside && this.sidebarOpen) {
      this.sidebarOpen = false;
    }
  }


  getTrainingNotificationsMessageByEmployeeNumber() {
    this.trainingService.getTrainingNotificationsMessageByEmployeeNumber(this.NotificationPagingInfo).subscribe((d: any) => {
      if (d.data.length > 0) {
        this.trainingNotificationList = d.data;
        this.NotificationPagingInfo.totalItems = d.data[0]["TotalCount"];
        // if (this.trainingNotificationList.length > 0 && jQuery.isEmptyObject(kioskSession.getData("trainingNotificationsShown"))) {
        //   $("#trainingNotificationModal").modal('show');
        //   kioskSession.addData("trainingNotificationsShown", { isEmpty: true });
        // }
      }

    });
  };

  getNotifications() {
    this.homeService.getNotifications(this.employeeNumber).subscribe((d: any) => {
      this.notifications = d;
      // if (this.notifications.length > 0 && jQuery.isEmptyObject(kioskSession.getData("notificationsShown"))) {
      //     $("#notificationModal").modal('show');
      //     kioskSession.addData("notificationsShown", { isEmpty: true });
      // }

    });
  };

  isDetailsPage(): boolean {
    return this.router.url.includes('/details');
  }

}
