import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { DailogComponent } from './dialog/dailog.component';
import { SettingComponent } from './setting/setting.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './spinner/spinner.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ProfileComponent } from './profile/profile.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { MaterialModule } from '../material.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { RemoveNaNPipe } from '../pipes/remove-nan.pipe';
import { SortByPipe } from '../pipes/sort-by.pipe';
import { EquipmentComponent } from '../pages/home/details/equipment/equipment.component';
import { InventoryComponent } from '../pages/home/details/inventory/inventory.component';
import { AddEquipmentDialogComponent } from '../pages/home/details/add-equipment-dialog/add-equipment-dialog.component';
import { ReviewDetailsComponent } from '../pages/home/details/review-details/review-details.component';
import { NotificationPopupComponent } from './notification-popup/notification-popup.component';
import { ItPasswordResetComponent } from './it-password-reset/it-password-reset.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { KeyboardComponent } from './keyboard/keyboard.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { InputKeyboardComponent } from './input-keyboard/input-keyboard.component';
import { TitleCasePipe } from '../pipes/case.pipe';
import { RcDirective } from '../directives/rc.directive';
import { ReactiveKeyboardComponent } from './reactive-keyboard/reactive-keyboard.component';
import { TooltipPositionDirective } from '../directives/tooltip-position.directive';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { OperatorDropdownComponent } from './dynamic-table/operator-dropdown/operator-dropdown.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AdvanceDropdownComponent } from './advance-dropdown/advance-dropdown.component';
import { IsDatePipe } from './is-date.pipe';
import { IsBooleanPipe } from './is-boolean.pipe';
import { ResizeColumnDirective } from '../directives/resize-column.directive';
import { OnlyNumberDirective } from '../directives/only-number.directive';
import { UnsavedDialogComponent } from './unsaved-dialog/unsaved-dialog.component';
import { UppercaseDirective } from '../directives/uppercase.directive';
import { FloatingPointDirective } from '../directives/floating-point.directive';
import { FloatingPointCommaDirective } from '../directives/floating-point-comma.directive';
import { MaintenanceComponent } from './profile/maintenance/maintenance.component';


@NgModule({
  declarations: [
    CardComponent,
    DailogComponent,
    SettingComponent,
    SpinnerComponent,
    ChangePasswordComponent,
    ProfileComponent,
    CalculatorComponent,
    ConfirmDialogComponent,
    SortByPipe,
    EquipmentComponent,
    InventoryComponent,
    AddEquipmentDialogComponent,
    ReviewDetailsComponent,
    NotificationPopupComponent,
    ItPasswordResetComponent,
    PaginatorComponent,
    KeyboardComponent,
    AlertDialogComponent,
    InputKeyboardComponent,
    ReactiveKeyboardComponent,
    TitleCasePipe,
    RcDirective,
    TooltipPositionDirective,
    RemoveNaNPipe,
    DynamicTableComponent,
    OperatorDropdownComponent,
    AdvanceDropdownComponent,
    // UniquePipe,
    IsDatePipe,
    IsBooleanPipe,
    OnlyNumberDirective,
    UppercaseDirective,
    FloatingPointDirective,
    ResizeColumnDirective,
    UnsavedDialogComponent,
    FloatingPointCommaDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxSkeletonLoaderModule,
    MaintenanceComponent
  ],
  exports: [
    CardComponent,
    SpinnerComponent,
    CalculatorComponent,
    ConfirmDialogComponent,
    SortByPipe,
    EquipmentComponent,
    InventoryComponent,
    AddEquipmentDialogComponent,
    ReviewDetailsComponent,
    PaginatorComponent,
    KeyboardComponent,
    InputKeyboardComponent,
    ReactiveKeyboardComponent,
    TitleCasePipe,
    RcDirective,
    TooltipPositionDirective,
    RemoveNaNPipe,
    DynamicTableComponent,
    OperatorDropdownComponent,
    AdvanceDropdownComponent,
    ResizeColumnDirective,
    OnlyNumberDirective,
    UppercaseDirective,
    FloatingPointDirective,
    UnsavedDialogComponent,
    FloatingPointCommaDirective
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
