import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    selector: '[floatingPoint]',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FloatingPointDirective),
        multi: true
    }]
})
export class FloatingPointDirective implements ControlValueAccessor {
    @Input() decimalPlaces: number = 2; // Default to 2 decimal places
    private onChange!: (val: string) => void;
    private onTouched!: () => void;
    private value: string = '';

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) { }

    // Detect changes in the @Input() decimalPlaces
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['decimalPlaces'] && !changes['decimalPlaces'].firstChange) {
            // Reformat the current value if decimalPlaces changes
            const currentValue = this.elementRef.nativeElement.value;
            const formattedValue = this.formatToFloatingPoint(currentValue, false);
            this.updateTextInput(formattedValue, false);
        }
    }

    @HostListener('focus')
    onFocus(): void {
        const currentValue = this.elementRef.nativeElement.value;
        if (currentValue === this.formatEmptyValue()) {
            this.selectEntireValue();
        }
    }

    @HostListener('blur')
    onBlur(): void {
        const value = this.formatToFloatingPoint(this.elementRef.nativeElement.value);
        this.updateTextInput(value, this.value !== value);
        this.onTouched();
    }

    @HostListener('input', ['$event'])
    onInput(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        const cursorPosition = inputElement.selectionStart || 0;

        const rawValue = inputElement.value;
        const formattedValue = this.formatToFloatingPoint(rawValue, true);

        // Update the input value without modifying the cursor position
        this.updateTextInput(formattedValue, true);

        // Restore the cursor position
        this.renderer.setProperty(this.elementRef.nativeElement, 'selectionStart', cursorPosition);
        this.renderer.setProperty(this.elementRef.nativeElement, 'selectionEnd', cursorPosition);
    }

    private formatToFloatingPoint(value: string, allowManualDecimal: boolean = false): string {
        let cleanedValue = value.replace(/[^0-9.-]/g, '');

        // Forcefully apply decimal formatting on blur
        if (!allowManualDecimal) {
            let parsedValue = parseFloat(cleanedValue);
            if (!isNaN(parsedValue)) {
                cleanedValue = parsedValue.toFixed(this.decimalPlaces);
            } else {
                cleanedValue = this.formatEmptyValue();
            }
        }

        return cleanedValue;
    }

    private formatEmptyValue(): string {
        return `0.${'0'.repeat(this.decimalPlaces)}`;
    }

    private updateTextInput(value: string, propagateChange: boolean): void {
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
        if (propagateChange) {
            this.onChange(value);
        }
        this.value = value;
    }

    private selectEntireValue(): void {
        setTimeout(() => {
            this.elementRef.nativeElement.select();
        });
    }

    // ControlValueAccessor Interface
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    }

    writeValue(value: any): void {
        if (value === null || value === undefined || value === '') {
            value = this.formatEmptyValue();
        } else {
            value = this.formatToFloatingPoint(String(value));
        }
        this.updateTextInput(value, false);
    }
}
