import { Component, HostBinding, effect, signal } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import * as moment from 'moment';
import { ProfileComponent } from 'src/app/shared/profile/profile.component';
import { MatDialog } from '@angular/material/dialog';
import { HomeService } from 'src/app/pages/home/home.service';
import { ThemeService } from 'src/app/theme.service';
import { RcService } from 'src/app/core/services/rc.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  currentUser: any;
  currentDateTime: string = '';
  employeeNumber: number = 0;
  notifications: any;
  isSupervisor: any;
  isReviewer: any;

  isDarkTheme: boolean = false;

  constructor(
    private themeService: ThemeService,
    public rcService: RcService,
    private authService: AuthService,
    private dialogRef: MatDialog,
    private homeService: HomeService,
  ) {
  }

  ngOnInit() {
    this.themeService.isDarkTheme$.subscribe(isDarkTheme => {
      this.isDarkTheme = isDarkTheme;
      document.body.classList.toggle('dark', isDarkTheme);
    });

    this.updateTimer(); // Initial update

    // Update the timer every second
    setInterval(() => {
      this.updateTimer();
    }, 1000);

    this.authService.getCurrentUser().subscribe((user) => {
      this.currentUser = user;
      this.employeeNumber = user?.credentials?.EmployeeNumber;
      this.isSupervisor = user?.isSupervisor;
      this.isReviewer = user?.canReviewFirstLevel;

    });
    this.getNotifications();
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  updateTimer() {
    const currentDate = moment();
    // this.currentDateTime = currentDate.format('ddd, MMM DD, hh:mm:ss A');
    this.currentDateTime = currentDate.format('MM/DD/YYYY hh:mm:ss A');
  }

  // setDarkMode() {
  //   this.themeMode = this.themeMode === "light" ? "dark" : "light";
  //   localStorage.setItem('darkMode', this.themeMode);
  //   document.body.classList.toggle(this.themeMode);
  //   let themeMode = window.localStorage.getItem('darkMode');
  //   console.log('themeMode', themeMode);
  //   console.log('this.themeMode', this.themeMode);
  // };

  openProfileDailog() {
    this.dialogRef.open(ProfileComponent);
  }

  getNotifications() {
    this.homeService.getNotifications(this.employeeNumber).subscribe({
      next: (response) => {
        this.notifications = response;
      },
      error: (error) => {
        console.log("getNorifications error : ", error);
      }
    }
    )
  };

  // @HostBinding('class.dark') get mode() {
  //   return this.darkMode();
  // }

  // constructor() {
  //   effect(() => {
  //     window.localStorage.setItem('darkMode', JSON.stringify(this.darkMode));
  //     this.darkMode = window.localStorage.getItem('darkMode');
  //   })
  // }

}
