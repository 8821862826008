// jwt.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../services/auth.service';
import { ConstantsService } from '../services/constants.service';
import { Router } from '@angular/router';
import { RcService } from '../services/rc.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private rcService: RcService,
    private authService: AuthService,
    private constantService: ConstantsService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    const rc = this.rcService.getTenant();

    // Get the current path
    const currentPath = this.router.url;

    // Determine the API URL based on the current path
    let apiUrl: string;
    if (
      currentPath.includes('/inventory')
      && (!request.url.includes('/authenticate')
        && !request.url.includes('/profile')
        && !request.url.includes('/employeestatus/getnotifications')
        && !request.url.includes('/Training')
        && !request.url.includes('/supervisor')
        && !request.url.includes('/employeehours')
        // maintenance
        && !request.url.includes('/getgroups')
        && !request.url.includes('/getreviewgroups')
        && !request.url.includes('/getTCKModuleControlMasterForMaintenance')
        && !request.url.includes('/getTCKTimeCardSettingsForMaintenance')
        && !request.url.includes('/getHolidaysObservedList')
        && !request.url.includes('/saveHolidaysObserved')
      )
    ) {
      // Set a different API URL based on path
      apiUrl = this.constantService.INVENTORY_API_URL; // Inventory URL for a specific path
    } else if (request.url.includes('/Authenticate/GetInventoryAccess')) {
      apiUrl = this.constantService.INVENTORY_API_URL;
    } else {
      // Use the default API URL
      apiUrl = this.constantService.API_URL;
    }
    if (token) {
      request = request.clone({
        url: `${apiUrl}/${request.url}`,
        setHeaders: {
          Authorization: `Bearer ${token}`,
          // Referer: rc
        },
      });
    } else {
      request = request.clone({
        url: `${apiUrl}/${request.url}`,
      });
    }

    return next.handle(request);
  }
}
