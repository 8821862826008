<div class="table-container">
  <!-- w-full head-table-witoutfootHt font-avertareguler m-table-container bg-white -->
  <div
    class="w-full head-table-witoutfootHt font-avertareguler m-table-container bg-white"
  >
    <table
      #table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8 resizable-table border border-slate-500"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="expandableDetail" *ngIf="isNested">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length"
        >
          <ng-container *ngIf="expandedElement === element">
            <div class="expanded-detail">
              <h4 class="font-medium">Blanket Order Details</h4>
              <div class="child-table-container bg-white">
                <table
                  mat-table
                  [dataSource]="element.details"
                  class="nested-table"
                >
                  <ng-container matColumnDef="LineNumber">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="font-avertaBold"> Line Number </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="font-avertareguler">{{
                        element.LineNumber
                      }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="ItemNumber">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="font-avertaBold"> Item Number </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="font-avertareguler">{{
                        element.ItemNumber
                      }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="ItemDescription">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="font-avertaBold"> Item Description </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="font-avertareguler">{{
                        element.ItemDescription
                      }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="ItemDescription2">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="font-avertaBold"> Item Description 2 </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="font-avertareguler">{{
                        element.ItemDescription2
                      }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Location">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="font-avertaBold"> Location </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="font-avertareguler">{{
                        element.Location
                      }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="VendorItemNumber">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="font-avertaBold"> Vendor Item Number </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="font-avertareguler">{{
                        element.VendorItemNumber
                      }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="PurchaseQuantity">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="font-avertaBold"> Purchase Quantity </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="font-avertareguler">{{
                        element.PurchaseQuantity
                      }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="PurchaseCost">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="font-avertaBold"> Purchase Cost </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="font-avertaBold">{{
                        element.PurchaseCost
                      }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="ReceivedQuantity">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="font-avertaBold"> Received Quantity </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="font-avertaBold">{{
                        element.ReceivedQuantity
                      }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="ReceivedDate">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="font-avertaBold"> Received Date </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="font-avertareguler">{{
                        element.ReceivedDate | date : "MM/dd/yyyy"
                      }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="InvoicedQuantity">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="font-avertaBold"> Invoiced Quantity </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="font-avertaBold">{{
                        element.InvoicedQuantity
                      }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="InvoicedCost">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="font-avertaBold"> Invoiced Cost </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="font-avertaBold">{{
                        element.InvoicedCost
                      }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="InvoicedDate">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="font-avertaBold"> Invoiced Date </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="font-avertareguler">{{
                        element.InvoicedDate | date : "MM/dd/yyyy"
                      }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="font-avertaBold"> Status </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="font-avertareguler">{{
                        element.Status
                      }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="DetailNote">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="font-avertaBold"> Detail Note </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="font-avertareguler">{{
                        element.DetailNote
                      }}</span>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="[
                      'LineNumber',
                      'ItemNumber',
                      'ItemDescription',
                      'ItemDescription2',
                      'Location',
                      'VendorItemNumber',
                      'PurchaseQuantity',
                      'ReceivedQuantity',
                      'ReceivedDate',
                      'InvoicedQuantity',
                      'InvoicedCost',
                      'InvoicedDate',
                      'Status',
                      'DetailNote'
                    ]"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="
                      let row;
                      columns: [
                        'LineNumber',
                        'ItemNumber',
                        'ItemDescription',
                        'ItemDescription2',
                        'Location',
                        'VendorItemNumber',
                        'PurchaseQuantity',
                        'ReceivedQuantity',
                        'ReceivedDate',
                        'InvoicedQuantity',
                        'InvoicedCost',
                        'InvoicedDate',
                        'Status',
                        'DetailNote'
                      ]
                    "
                  ></tr>
                </table>
                <div
                  *ngIf="element.details.length === 0"
                  class="text-center p-4 text-lg font-semibold text-gray-500"
                >
                  No data found
                </div>
              </div>
            </div>
          </ng-container>
        </td>
      </ng-container>
      <ng-container
        *ngFor="let column of columns; trackBy: trackByFn"
        [matColumnDef]="column.columnDef"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [resizeColumn]="true"
          class="px-4 py-2 text-left text-xs font-semibold text-gray-600 border"
        >
          <div class="grid grid-cols-1">
            <div
              class="header-content flex items-center"
              mat-sort-header
              [disableClear]="false"
              [arrowPosition]="'after'"
            >
              <span>{{ column.header }}</span>
            </div>
            @if (column.header && column?.filterOptions?.length) {
            <div class="flex">
              <span
                class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border-gray-300 border-e-0 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600"
              >
                <span class="text-gray-500 dark:text-gray-400">
                  <app-operator-dropdown
                    [operators]="column.filterOptions || []"
                    (operatorSelected)="
                      onOperatorSelected($event, column.columnDef)
                    "
                    [dropdownId]="column.columnDef"
                    [defaultOperator]="column.defaultFilter || 'Contains'"
                  ></app-operator-dropdown>
                </span>
              </span>
              <div class="flex justify-between relative filter-input">
                <ng-container [ngSwitch]="column.dataType">
                  <span *ngSwitchCase="'date'">
                    <input
                      [matDatepicker]="tablePicker"
                      class="block w-full p-1 text-gray-900 border-gray-300 rounded-r-md bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="__/__/____"
                      (click)="tablePicker.open()"
                      [attr.id]="column.columnDef"
                      (dateInput)="applyDateChange($event, column.columnDef)"
                    />
                    <mat-datepicker touchUi #tablePicker></mat-datepicker>
                  </span>
                  <span *ngSwitchDefault>
                    <input
                      type="text"
                      class="block w-full p-1 text-gray-900 border-gray-300 rounded-r-md bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      (input)="applySearch($event, column.columnDef)"
                      [attr.data-column]="column.columnDef"
                    />
                    <!-- [attr.id]="column.columnDef" -->
                  </span>
                </ng-container>
                <button
                  *ngIf="filterCriteria[column.columnDef]?.value"
                  class="clear-input absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                  (click)="onClearSearchInput(column.columnDef)"
                >
                  &#x2715;
                </button>
              </div>
            </div>
            }
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="px-4 py-2 text-xs text-gray-700 border"
        >
          <ng-container [ngSwitch]="column.dataType">
            <span *ngSwitchCase="'toggle'">
              <button
                mat-icon-button
                (click)="toggleExpandRow(element, $event)"
                [disabled]="!hasDetails(element)"
              >
                @if (expandedElement === element) {
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="size-5"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                } @else {
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="size-5"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm.75-11.25a.75.75 0 0 0-1.5 0v2.5h-2.5a.75.75 0 0 0 0 1.5h2.5v2.5a.75.75 0 0 0 1.5 0v-2.5h2.5a.75.75 0 0 0 0-1.5h-2.5v-2.5Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                }
              </button>
            </span>
            <span *ngSwitchCase="'checkbox'">
              <mat-checkbox
                [checked]="element[column.columnDef]"
                (change)="toggleRow(element, $event)"
              >
              </mat-checkbox>
            </span>
            <span *ngSwitchCase="'string'">{{
              element[column.columnDef]
            }}</span>
            <span *ngSwitchCase="'account'">{{
              element[column.columnDef] | number : "1.3-10"
            }}</span>
            <span *ngSwitchCase="'number'">
              {{
                element[column.columnDef]
                  | number
                    : "1." +
                        (column?.decimalPlaces ?? 2) +
                        "-" +
                        (column?.decimalPlaces ?? 2)
              }}
            </span>
            <span *ngSwitchCase="'number6'">
              {{
                element[column.columnDef]
                  | number
                    : "1." +
                        (column?.decimalPlaces ?? 6) +
                        "-" +
                        (column?.decimalPlaces ?? 6)
              }}
            </span>
            <span *ngSwitchCase="'date'">{{
              element[column.columnDef] | date : "MM/dd/yyyy"
            }}</span>
            <mat-checkbox
              *ngSwitchCase="'boolean'"
              [checked]="element[column.columnDef]"
              disabled
            ></mat-checkbox>
          </ng-container>
        </td>
        <!-- Footer Cell -->
        <td
          mat-footer-cell
          *matFooterCellDef
          class="px-4 py-2 text-xs text-gray-700 border"
        >
          <!-- Directly access the precomputed total -->
          <ng-container *ngIf="footerTotals[column.columnDef] !== null">
            {{ footerTotals[column.columnDef] }}
          </ng-container>
        </td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
        class="thead"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="toggleRow(row)"
        [class.selected]="selectedRows.has(row)"
      ></tr>
      @if(isNested) {
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandableDetail']"
        class="detail-row"
      ></tr>
      }

      <!-- Footer Row -->
      @if(isTotal) {
      <tr
        mat-footer-row
        *matFooterRowDef="displayedColumns; sticky: true"
        class="tfoot"
      ></tr>
      }
    </table>
    <div
      *ngIf="length === 0"
      class="text-center p-4 text-lg font-semibold text-gray-500"
    >
      No data found
    </div>
  </div>
  <mat-paginator
    [pageSize]="10"
    [pageSizeOptions]="[10, 25, 50, 100]"
    [length]="length"
    (page)="onPageChange($event)"
    showFirstLastButtons
  ></mat-paginator>
</div>
